import React from 'react'
import { ThreeCircles } from 'react-loader-spinner'

export const Loading = () => {
  return (
    <div className='flex justify-center items-center'>
      <ThreeCircles type="Puff" color="#3b82f6" innerCircleColor="#ef4444" middleCircleColor="#eab308" height={550} width={80}/>
    </div>
  )
}
