import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { useResultContext } from '../contexts/ResultContextProvider'
import { Loading } from './Loading'

export const Results = () => {
  const { results, isLoading, getResults, searchTerm } = useResultContext()
  const location = useLocation()

  useEffect(() => {
    if(searchTerm) {
      if(location.pathname === '/videos') {
        getResults(`/search/q=${searchTerm} videos`)
      } else {
        getResults(`${location.pathname}/q=${searchTerm}&lr=lang_id`)
      }
    }
  }, [searchTerm, location.pathname])

  if(isLoading) return <Loading />
  if(!searchTerm) return (
    <div className="text-center mt-8">Tidak ada data!</div>
  )

  switch (location.pathname) {
    case '/search':
      return (
        <div className="flex flex-wrap justify-between space-y-6 sm:px-56 mt-5 px-3">
          { results?.map(({link, title, description}, index) => (
            <div className="md:w-full w-full" key={index}>
              <a href={link} target="_blank" rel="noreferrer">
                <p className='text-sm font-normal'>
                  {link.length > 30 ? link.substring(0, 30) : link}
                </p>
                <p className="text-lg hover:underline dark:text-blue-300 text-blue-700">
                  {title}
                </p>
                <p className='text-sm text-gray-800 dark:text-white'>{description}</p>
              </a>
            </div>
          ))}
        </div>
      )
    case '/image':
      return (
        <div className="flex flex-wrap justify-center items-center mt-5 px-3">
          {results?.map(({image, link: { href, title }}, index) => (
            <a href={href} className="sm:p-2 p-3" key={index} target="_blank" rel='noreferrer'>
              <img src={image?.src} alt={title} loading="lazy" />
              <p className="w-36 break-words text-xs mt-2">
                {title}
              </p>
            </a>
          ))}
        </div>
      )
    case '/news':
      return (
        <div className="flex flex-wrap justify-between space-y-6 sm:px-56 items-center mt-5 px-3">
          { results?.map(({links, id, source, title}) => (
            <div className="md:w-2/5 w-full" key={id}>
              <a href={links?.[0].href} target="_blank" rel="noreferrer" className='hover:underline'>
                <p className="text-lg dark:text-blue-300 text-blue-700">
                  {title}
                </p>
              </a>
              <div className="flex gap-4">
                <a href={source?.href} target="_blank" rel="noreferrer">
                  {source?.href}
                </a>
              </div>
            </div>
          ))}
        </div>
      )
    case '/videos':
      return (
        <div className="flex flex-wrap justify-center aligns-center mt-5 px-3">
          {results.map((video,index) => (
            <div className="p-2" key={index}>
              {video.additional_links?.[0].href && <ReactPlayer url={video.additional_links?.[0].href} controls width="350px" height="200px"/>}
            </div>
          ))}
        </div>
      )
  
    default:
      return 'Error'
  }
}
