import React from 'react'
import { Link } from 'react-router-dom'
import { Search } from './Search'

export const Navbar = ({ darkTheme, setDarkTheme }) => {
  return (
    <div className="p-5 pb-0 flex flex-wrap sm:justify-between justify-center items-center border-b dark:border-gray-700 border-gray-200">
      <div className="flex justify-between items-center space-x-5 w-screen">
        <Link to="/">
          {/* <p className="text-2xl bg-blue-500 font-bold text-white py-1 px-2 rounded dark:bg-gray-500 dark:text-white">
            GatotKaca 🔎
          </p> */}

          <p className="text-3xl tracking-normal font-bold py-1 px-2 uppercase mt-2">
            <span className='text-blue-500'>G</span><span className='text-red-500'>a</span><span className='text-yellow-500'>t</span><span className='text-blue-500'>o</span><span className='text-green-500'>t</span> <span className='text-red-500'>K</span><span className='text-blue-500'>a</span><span className='text-green-500'>c</span><span className='text-yellow-500'>a</span>
          </p>
          
        </Link>
        <button type='button' onClick={() => setDarkTheme(!darkTheme)} className="text-xl dark:bg-gray-50 dark:text-gray-900 text-sm bg-white border rounded-full px-2 py-1 hover:shadow-lg">
            {darkTheme ? '💡 Terang' : '🌙 Gelap'}
        </button>
      </div>
      <Search />
    </div>
  )
}
