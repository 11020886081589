import React, { useState } from "react";

import { Navbar  } from "./components/Navbar";
import { Footer  } from "./components/Footer";
import { Routes  } from "./components/Routes";
import { useLocation } from "react-router-dom";

const App = () => {
  const [ darkTheme, setDarkTheme] = useState(false)
  const location = useLocation();

  return (
    
    <div className={darkTheme ? 'dark' : ''}>
      <div className="bg-white dark:bg-gray-900 dark:text-gray-200 min-h-screen">
        {location.pathname === '/' ? <Routes/> : 
        <>
          <Navbar darkTheme={darkTheme} setDarkTheme={setDarkTheme}/>
          <Routes/>
          <Footer/>
        </>
        }


      </div>
    </div>
  )
}

export default App;