import React, { useEffect, useState } from 'react'
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useResultContext } from '../contexts/ResultContextProvider';
import { useDebounce } from 'use-debounce';
import { useNavigate } from "react-router-dom"

export const Home = () => {
  let history = useNavigate ();
  const [text, setText] = useState('')
  const {setSearchTerm} = useResultContext()
  const [debouncedValue] = useDebounce(text, 300)

  const keyPressHandler = (e) => {
    if (e.which === 13) {
      e.preventDefault()
      setSearchTerm(e.target.value)
      // // alert("You pressed enter!");
      history("/search");
    }
  };

  useEffect(() => {
    if(debouncedValue) setSearchTerm(debouncedValue)
  }, [debouncedValue])

  return (
    <div className="flex flex-col items-center h-screen ">
      {/*body*/}
      <form className="flex flex-col justify-center items-center flex-grow w-4/5">
        <img
          src="https://img.freepik.com/premium-vector/gatotkaca-chibi-mascot-logo_408559-23.jpg?w=700"
          height={150}
          width={150}
          className="-mt-20"
        />

          <p className="text-3xl md:text-5xl tracking-normal text-center font-bold py-1 px-2 uppercase mt-2">
            <span className='text-blue-500'>G</span><span className='text-red-500'>a</span><span className='text-yellow-500'>t</span><span className='text-blue-500'>o</span><span className='text-green-500'>t</span> <span className='text-red-500'>K</span><span className='text-blue-500'>a</span><span className='text-green-500'>c</span><span className='text-yellow-500'>a</span>
          </p>
        <div
          className="flex w-full mt-5 hover:shadow-lg focus-within:shadow-lg max-w-md rounded-full
      border border-gray-200 px-5 py-3 items-center sm:max-w-xl lg:max-w-2xl"
        >
          <MagnifyingGlassIcon className="h-5 mr-3 text-gray-500" />
          <input
            type="text"
            defaultValue=""
            className="flex-grow focus:outline-none dark:bg-gray-900 outline-none text-black dark:text-white"
            // onChange={(e) => setText(e.target.value)} 
            onKeyDown={(e) => keyPressHandler(e)
            }/>
        </div>
      </form>

    </div>
  )
}

export default Home