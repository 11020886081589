import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { useResultContext } from '../contexts/ResultContextProvider'
import { Links } from './Links'

export const Search = () => {
  const [text, setText] = useState('')
  const {searchTerm, setSearchTerm} = useResultContext()
  const [debouncedValue] = useDebounce(text, 300)

  useEffect(() => {
    if(debouncedValue) setSearchTerm(debouncedValue)
  }, [debouncedValue])

  return (
    <div className='relative sm:ml-48 md:mx-auto sm:-mt-10 mt-3'>
      <input 
        type="text" 
        value={text ? text : searchTerm}
        className="sm:w-96 w-80 h-0 dark:bg-gray-200 border rounded-full shadow-sm outline-none p-5 text-black hover:shadow-lg"
        placeholder='Cari sesuatu ...'
        onChange={(e) => setText(e.target.value)} /> 
        {text && (
          <button className="absolute top-1 right-4 text-2xl text-gray-500" type='button' onClick={() => setText('')}>x</button>
        )}
      <Links/>
    </div>
  )
}
