import React, { createContext, useContext, useState} from "react";

const ResultContext = createContext();
const baseUrl = 'https://google-search3.p.rapidapi.com/api/v1'

export const ResultContextProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const getResults = async (type) => {
    setIsLoading(true);

    const response = await fetch(`${baseUrl}${type}`, {
      method: 'GET',
      headers: {
        'X-User-Agent': 'desktop',
        'X-Proxy-Location': 'US',
        'X-RapidAPI-Key': '455c9cafdamshcf8df0ad5042661p1db4c8jsnb6bce63fca44',
        'X-RapidAPI-Host': 'google-search3.p.rapidapi.com'
      }
    })

    const data = await response.json()

    if(type.includes('/news')) {
      setResults(data.entries)
    } else if (type.includes('/image')){
      setResults(data.image_results)
    } else {
      setResults(data.results)
    }

    setIsLoading(false)
  }

  return(
    <ResultContext.Provider value={{ getResults, results, searchTerm, setSearchTerm, isLoading }}>
      {children}
    </ResultContext.Provider>
  )
}

export const useResultContext = () => useContext(ResultContext)