import React from 'react'
import { Routes as Switch, Route, Navigate } from 'react-router-dom'

import { Results } from './Results'
import Home from './Home'

export const Routes = () => {

  return (
    <div className=''>
      <Switch>
        {/* <Route 
          exact 
          path="/"
          element={<Navigate to="/search"/>}>
        </Route> */}
        <Route 
          exact 
          path="/"
          element={<Home/>}>
        </Route>
          <Route exact path='/search' element={<Results />} />
          <Route exact path='/image' element={<Results />} />
          <Route exact path='/news' element={<Results />} />
          <Route exact path='/videos' element={<Results />} />
      </Switch>
    </div>
  )
}
