import React from 'react'
import { NavLink } from 'react-router-dom'

const links = [
  { url: '/search', text: '🔎 Semua' },
  { url: '/news', text: '📰 Berita' },
  { url: '/image', text: '📸 Gambar' },
  { url: '/videos', text: '📺 Video' },
]

export const Links = () => {
  return (
    <div className='flex sm:justify-around justify-between items-center text-center mt-4'>
      {links.map(({url, text}, index) => (
        <NavLink to={url} key={index} className={(navData) => (navData.isActive ? "text-blue-700 border-b-2 dark:text-blue-300 border-blue-700 pb-2 m-2" : 'none m-2')}>
          {text}
        </NavLink>
      ))}
    </div>
  )
}